import { defineStore } from 'pinia'

export const useUtilsStore = defineStore({
  id: 'utils-store',
  state: () => {
    return {
      mailingListDialog: false,
      sidebarOpen: false,
      podcastDialog: false,
      imageUploadDialog: false,
      imageUploadDialogDescription: null,
      planUpdatedDialog: false,
      confirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogTitle: '',
      confirmDialogCallback: null,
      feedbackFormDialog: false,
      selectTimecodesDialog: false,
      noticeDialog: false,
      noticeDialogMessage: '',
      noticeDialogTitle: '',
      iframeEmbedDialog: false,
      backgroundAudioDialog: false,
      backgroundVideoDialog: false,
      videoPlayerDialog: false,
      subtitlesUploadDialog: false,
      signupDialog: false,
      signupCallback: null,
      upgradeDialog: false,
      upgradeDialogType: 'video',
      generateVideoDialog: false,
      generateVideoImage: null,
      createClipDialog: false,
      createClipDialogCallback: null,
      createClipDialogName: '',
      createClipDialogTranscribe: false,
      audioClipSelectDialog: false,
    }
  },
  actions: {
    async confirmCallback() {
      if (this.confirmDialogCallback) {
        this.confirmDialogCallback();
      }
      this.confirmDialog = false;
    },
  }
});